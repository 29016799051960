<template>
  <div>
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>Daftar Informasi Penting</span>
        <p class="text-center">
          Daftar Informasi Penting Badan Penyelenggara Jaminan Produk Halal
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container pad-20">
        <div class="content-detail-layanan">
          <div class="for-all-menu-information flex">
            <div class="col-md-12">
              <form @submit.prevent="cariInformasi">
                <div class="input-group for-search">
                  <input type="text" placeholder="Cari Informasi Penting" v-model="search" class="input-for-search">
                  <button type="submit" class="btn-search">
                    <i class="fa fa-search fa-flip-horizontal"></i>
                  </button>
                </div>
              </form>
              <template v-for="(info, i) in informations">
                <a @click="redirectTo(info)" class="item cursor-pointer" :key="i">
                  <h4 class="elipsis-2-char ln-c-7 post-title">{{ info.title}}</h4> 
                  <template v-if="info.type == 'download'">
                    <a class="for-download-file all" href="#">
                      <img src="@/assets/images/compro/icon/download.png" alt="">
                    </a>
                  </template> 
                  <template v-else-if="info.type == 'redirect'">
                    <a class="for-link all" href="#" style="right: 18px">
                      <img src="@/assets/images/compro/icon/chain.svg" alt="">
                    </a>
                  </template>
                  <template v-else-if="info.type == 'blog-post'">
                    <a class="for-link all" href="#" style="right: 18px">
                      <img src="@/assets/images/compro/icon/file.svg" alt="">
                    </a>
                  </template>
                  <span>{{ info.timestamp | date_only}} | {{ info.timestamp | time_only }}</span>
                </a>
              </template>
              <div class="mt-3 pb-3 no-last style_me"> 
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'public-information',
  data() {
    return {
      informations: [],
      search: null,
      rows: 0,
      perPage: 10,
      currentPage: 1
    }
  },
  watch: {
    currentPage() {
      this.getInformation()
    }
  },
  mounted() {
    this.getInformation()
  },
  methods: {
    cariInformasi() {
      this.currentPage = 1
      this.getInformation()
    },
    getInformation() {
      this.axios.get(`information`, {
        params: {
          category: 'important',
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          status: 1,
        }
      }).then( response => {
        this.informations = response.data.result.informations
        this.rows = response.data.result.total
      })
    },
    redirectTo(info) {
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.open(info.file, '_blank');
      } else {
        this.$router.push(`/information/${info.slug}`)
      }
    }
  }
}
</script>